import '/assets/styles/pages/campaign-detail.scss';

import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// Partials
import Placeholder from '/views/partials/placeholder'
import Icon from 'pstv-commerce-tools/partials/icon';
import Link from 'pstv-commerce-tools/partials/link';
import Img from 'pstv-commerce-tools/partials/img';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';
import { useParams } from 'pstv-commerce-tools/utilities/navigator'
import { useGlobalState } from 'pstv-commerce-tools/utilities/global-state';

// Services
import siteServices from '/services/site';

// Sections
import Listing from '/views/sections/listing';

// Functions
import getCdnImage from '/functions/get-cdn-image'

const getCampaignCdnImage = (image, width, height) => {
	return getCdnImage(image, {
		width: width,
		height: height,
		bgColor: 'white',
		quality: 50,
		mode: 'crop',
	});
}

const getCampaign = (campaignID) => {
	return new Promise((resolve, reject) => {
		siteServices.getCampaignDetail(campaignID).then(campaign => {
			resolve({
				...campaign,
				images: {
					web: getCampaignCdnImage(campaign.image, 1280, 505),
					web2x: getCampaignCdnImage(campaign.image, 2560, 1010),
					mobile: getCampaignCdnImage(campaign.image, 678, 268),
				}
			});
		}).catch(reject)
	});
}

const getCampaignProducts = (campaignID) => {
	return siteServices.getCampaignProducts(campaignID);
}

const CampaignDetail = ({ pageProps }) => {
	const { desktop } = useBreakpoints();
	const { setNotFound } = useGlobalState();
	const { id: campaignId } = useParams('campaignDetail');

	const [campaign, setCampaign] = useState(pageProps.campaign ?? false);
	const [campaignProducts, setCampaignProducts] = useState(pageProps.campaignProducts ?? false);

	useEffect(() => {
		if(campaignId && campaign?.id !== parseInt(campaignId)) {
			setCampaignProducts(false);
			getCampaign(campaignId).then(campaignData => {
				setCampaign(campaignData);
			}).catch(() => {
				setNotFound();
			});

			getCampaignProducts(campaignId).then(productData => {
				setCampaignProducts(productData);
			}).catch(() => {
				setCampaignProducts([]);
			})
		}
	}, [campaign, campaignId]);

	return (
		<>
			<div className="campaign-detail-wrap wrapper">
				<div className="detail-header">
					<Link href="campaigns" className="header-backbtn">
						<Icon name="angle-left" />
					</Link>
					<h1 className="header-title">Kampanya Detayı</h1>
				</div>

				<main className="detail-content">
					{campaign ?
						<>
							<div className="content-imagewrap">
								<Img
									cover
									className="content-image"
									srcSet={desktop ? `${campaign.images.web2x} 2x` : undefined}
									src={desktop ? campaign.images.web : campaign.images.mobile} />
							</div>
							<strong className="content-title">
								Kampanya Detayları
							</strong>

							<div
								className="content-text wysiwyg"
								dangerouslySetInnerHTML={{__html: campaign.description}} />
						</>
						:
						<>
							<Placeholder className="content-imagewrap" />
							<Placeholder className="content-title" />
							<Placeholder className="content-text" />
						</>
					}
				</main>
			</div>

			{campaignProducts !== false && !(campaignProducts && campaignProducts.length === 0) &&
				<div className="section campaign-detail-products">
					<div className="wrapper">
						<h2 className="products-title">Kampanya Ürünleri</h2>
					</div>
					<Listing className="products-list" products={campaignProducts ?? [false, false, false, false, false, false, false, false, false, false, false, false]} />
				</div>
			}
		</>
	)
}

CampaignDetail.propTypes = {
	children: PropTypes.object,
	pageProps: PropTypes.object
}

CampaignDetail.getServerProps = ({ match }) => {
	return new Promise((resolve) => {
		Promise.all([
			getCampaign(match.params.id),
			getCampaignProducts(match.params.id),
		]).then(([campaign, campaignProducts]) => {
			resolve({
				pageProps: {
					campaign,
					campaignProducts,
				},
			})
		}).catch(() => {
			resolve({
				status: 500,
			})
		})
	})
}

export default CampaignDetail
