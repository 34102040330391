import '/assets/styles/partials/pagination.scss';
import { useMemo } from 'react'
import PropTypes from 'prop-types';

// Context
import { useBreakpoints } from 'pstv-commerce-tools/utilities/breakpoints';

// Partials
import Btn from '/views/partials/btn'

const paginationWidth = process.config.paginationWidth ?? 3;
const mobilePaginationWidth = process.config.mobilePaginationWidth ??  1;

// Pagination
const Pagination = ({ data, onPaginate, className }) => {
	const { mobile } = useBreakpoints();

	const content = useMemo(() => {
		if(!data || data.page_count === 0) {
			return false;
		}
		const newContent = [];

		let start = data.page - (mobile ? mobilePaginationWidth : paginationWidth) - 1;
		let end = data.page + (mobile ? mobilePaginationWidth : paginationWidth) - 1;

		if (start < 0) {
			start = 0;
		}
		if (end > (data.page_count - 1)) {
			end = data.page_count - 1;
		}

		if (start > 0) {
			newContent.push('1');
			newContent.push(false);
		}

		for (let k = start; k <= end; k++) {
			const pg = k + 1;
			newContent.push(pg.toString());
		}

		if (end < data.page_count - 1) {
			newContent.push(false);
			newContent.push(data.page_count.toString());
		}

		return newContent;
	}, [data, mobile])

	if(content) {
		return (
			<nav className={'pagination ' + (className ? className : '')}>
				<Btn
					className="pagination-navbtn outline small prev"
					icon="angle-left"
					disabled={data.page === 1}
					onClick={() => { onPaginate(data.page - 1) }}>
					Önceki{!mobile ? ' Sayfa' : ''}
				</Btn>
				<div className="pagination-nums">
					{mobile ?
						<>
							<label className="nums-display" htmlFor="pagination-mobileselector">
								{data.page} / {data.page_count}
								
								<select
									className="display-select"
									value={data.page.toString()}
									onChange={(e) => { onPaginate(e.target.value) }}
									id="pagination-mobileselector">
									{content.map((page, nth) => {
										if(page) { return (
											<option value={page} key={nth}>{page}</option>
										)}
										else { return (
											<option value="" key={nth} disabled>...</option>
										)}
									})}
								</select>
							</label>
						</>
						:
						<>
							{content.map((page, nth) => (
								<PageBtn
									page={page}
									key={nth}
									curPage={data.page}
									onClick={onPaginate} />
							))}
						</>
					}
				</div>
				<Btn
					className="pagination-navbtn outline small next"
					afterIcon="angle-right"
					disabled={data.page === data.page_count}
					onClick={() => { onPaginate(data.page + 1) }}>
					Sonraki{!mobile ? ' Sayfa' : ''}
				</Btn>
			</nav>
		)
	}
	else { return null; }
}

Pagination.propTypes = {
	className: PropTypes.string,
	onPaginate: PropTypes.func,
	data: PropTypes.object,
}

const PageBtn = ({ page, curPage, onClick }) => {
	const active = curPage.toString() === page;
	return (
		<>
			{page ?
				<button
					type="button"
					disabled={active}
					onClick={() => { onClick(page); }}
					className={'pagination-button' + (active ? ' active' : '')}>
					{page}
				</button>
			:
				<span className="pagination-seperator">...</span>
			}
		</>
	)
}

PageBtn.propTypes = {
	page: PropTypes.oneOfType([PropTypes.string,PropTypes.oneOf([false])]),
	curPage: PropTypes.number,
	onClick: PropTypes.func,
}

export default Pagination;
