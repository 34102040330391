import '/assets/styles/sections/listing.scss';
import { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

// Partials
import Productbox from '/views/partials/productbox';
import Loader from '/views/partials/loader';
import Pagination from '/views/partials/pagination';
import EmptyMessage from '/views/partials/empty-message';

// Context
import { useListing } from 'pstv-commerce-tools/utilities/listing';

const Listing = ({ className, products: rawProducts, pagination, emptyMessage, wrap, onProductClick }) => {
	const { paginate } = useListing();

	const products = useMemo(() => {
			return rawProducts ?? [
				false, false, false, false, false, false, false, false, false, false,
				false, false, false, false, false, false, false, false, false, false,
				false, false, false, false, false, false, false, false, false, false,
			]
	}, [rawProducts])

	const computedEmptyMessage = useMemo(() => {
		return <EmptyMessage icon="search" className="listing-emptymessage">{emptyMessage ?? 'Aradığınız kriterlerde bir ürün bulunamadı.'}</EmptyMessage>;
	}, [emptyMessage])

	return (
		<section className={`section listing ${className}`}>
			<div className={`${wrap === false ? '' : 'wrapper '}listing-wrap`}>
				<ul className="listing-products loader-container">
					<Loader loading={!products} message="Ürünler getiriliyor..." />
					{products &&
						<>
							{products.length > 0 ?
								<>
									{products.map((product, nth) => (
										<Fragment key={`p-${nth}-${product?.id ?? nth}`}>
											<li className="products-product">
												<Productbox
													onClick={onProductClick}
													preload={nth === 0}
													product={product} />
											</li>
										</Fragment>
									))}
								</>
								:
								<>{computedEmptyMessage}</>
							}
						</>
					}
				</ul>
				<Pagination
					className="results-pagination"
					data={pagination}
					onPaginate={paginate} />
			</div>
		</section>
	)
}

Listing.propTypes = {
	className: PropTypes.string,
	products: PropTypes.array,
	pagination: PropTypes.object,
	emptyMessage: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
	onProductClick: PropTypes.func,
	wrap: PropTypes.bool,
}


export default Listing;
