import '/assets/styles/partials/productbox/productbox.scss';

import { useMemo } from 'react'
import loadable from '@loadable/component';
import PropTypes from 'prop-types';

// Context
import { useGlobalEvents } from 'pstv-commerce-tools/utilities/global-events'

const types = {
	regular: loadable(() => import('./types/regular')),
	horizontal: loadable(() => import('./types/horizontal')),
	basket: loadable(() => import('./types/basket')),
	summary: loadable(() => import('./types/summary')),
}

const Productbox = ({ type = 'regular', className: classNameProp = '', product, onClick, ...props }) => {
	const { triggerEvent } = useGlobalEvents();
	const BoxElem = types[type] ?? types['regular'];
	const className = useMemo(() => {
		return `productbox ${classNameProp}`;
	}, [classNameProp])

	const onClickEvent = e => {
		triggerEvent('productClick', {
			product,
		});

		if(onClick) {
			onClick(e);
		}
	}

	return <BoxElem
		{...props}
		onClick={onClickEvent}
		product={product}
		className={className} />
}

Productbox.initialProps = {
	preload: false,
}

Productbox.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	preload: PropTypes.bool,
	product: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
	onClick: PropTypes.func,
}

export default Productbox
